<template>
  <div>
    <a-row>
      <a-col :span="isDesktop ? 18 : 24" style="padding-right: 10px">
        <a-col :span="24">
          <HomeFilter />
        </a-col>
        <a-col :span="24"> <MyProcesses /> </a-col
      ></a-col>
      <a-col :span="6" style="padding-left: 10px" v-if="isDesktop">
        <a-col :span="24">
          <NewProcessButton />
        </a-col>
        <a-col :span="24"><RecentProcess /> </a-col
      ></a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import NewProcessButton from "@/components/NewProcessButton/NewProcessButton";
import RecentProcess from "@/components/RecentProcess/RecentProcess";
import MyProcesses from "@/components/MyProcesses/MyProcesses";
import HomeFilter from "@/components/HomeFilter/HomeFilter";
import { isDesktop } from "@/utils/isAgent";
export default defineComponent({
  components: {
    RecentProcess,
    MyProcesses,
    HomeFilter,
    NewProcessButton,
  },
  setup() {
    return { isDesktop };
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
